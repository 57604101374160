import axios from '@axios'

/* eslint-disable import/prefer-default-export */
export const fetchStaffList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/staffs/', { params })
  return res
}

export const fetchStaff = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/staffs/${id}`)
  return res
}
export const exportStaff = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/users/export', { params })
  return res
}

export const deleteStaff = async (id: string): Promise<any> => {
  const res = await axios.delete(`/auth/staffs/${id}`)
  return res
}
